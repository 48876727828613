<template>
  <div>
    <ejs-dialog
        v-if="isShowMutilSelectPopup"
        ref="dialog"
        :header="title"
        width="600"
        height="auto"
        @close="onClickClose"
    >
      <div class="window" >
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <ul class="lookup-condition">
                <ul class="content">
                  <li class="item" >
                      <ejs-multiselect
                          ref="multiSelect"
                          v-model="multiSelect.result"
                          :dataSource="multiSelect.dataSource"
                          :fields="multiSelect.fields"
                          cssClass="lookup-condition-multiselect"
                          width="580"/>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <div class="windowFooter">
            <ul class="button">
              <li class="create keyColor">
                <erp-button button-div="SAVE" :is-shortcut-button="true" @click.native="onClickSubmit">확인</erp-button>
              </li>
              <li class="close">
                <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: 'MultiSelectPopup',
  mixins: [commonMixin, messagePopupDialogMixin],
  components: {
    ErpButton,
  },
  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      title: "",
      isShowMutilSelectPopup: false,
    };
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {

  },
  computed: {

  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {

    //####################### Button function ###############################
    onClickSubmit() {
      this.$emit('popupConfirm', this.multiSelect);
      // this.hide();
    },
    onClickClose() {
      // this.hide();
      this.$emit('popupClosed');
    },
    //####################### 부모화면에서 호출하는 function ###############################
    initShow(title, multiSelect) {
      this.title = title;
      this.isShowMutilSelectPopup = true;
      this.multiSelect = multiSelect;
    },
  },
};
</script>
<style scoped>
.voc-section {
  width: 100% !important;
}
</style>