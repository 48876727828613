<template>
  <div class="content-wrapper">
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref = "planYear"
                    :format="DATE_FORMAT_YYYY"
                    :notClear="true"
                    depth="Decade"
                    v-model="searchFilter.planYear"
                    type="lookup-condition"
                    @change="onYearChange"
                />

              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>

    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <!-- ######## 목록 리스트 ########-->
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{gridField.title}}</div>
              <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="save keyColor"><erp-button button-div="SAVE" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSave">저장</erp-button></li>
                <li class="add"><erp-button button-div="SAVE" @click.native="onClickAddRow" v-show="isSave">추가</erp-button></li>
                <li class="delete"><erp-button button-div="SAVE" @click.native="onClickDelRow" v-show="isSave">삭제</erp-button></li>
                <li class="add"><erp-button button-div="SAVE" @click.native="onClickCopyPreYear" :disabled="gridField.count>0">이전년도복사</erp-button></li>
                <li class="print"><erp-button button-div="FILE" :ignore="isPopupOpened" @click.native="onClickExcel">Excel</erp-button></li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind ="initGrid"
                  @recordClick="onGridClick"
                  @queryCellInfo="onQueryCellInfo"
                  @headerCellInfo="onHeaderCellInfo"
                  @actionComplete="gridComplete"
                  @cellSaved="onCellSaved"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 팝업 -->
    <multi-select-popup
        v-if="isShowMutilSelectPopup"
        ref="multiSelectPopup"
        @popupClosed="onMutilSelectPopupClose"
        @popupConfirm="onMutilSelectPopupConfirm"
    />
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import InputDate from "@/components/common/datetime/InputDate.vue";
import {DATE_FORMAT_YYYY, } from "@/utils/date";
import moment from "moment";
import SalesPlan from "@/api/v2/management/SalesPlan";
import MultiSelectPopup from "@/views/management/popup/MultiSelectPopup.vue";


export default {
  name: "SalesPlanStd",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {MultiSelectPopup, ErpButton, EjsGridWrapper, InputDate,},

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      DATE_FORMAT_YYYY,
      isShowMutilSelectPopup:false,
      //조회 필드
      searchFilter: {
        planYear: null,
      },
      isSave: true,
      //드롭다운 코드
      commonCodeOptions: {
        classCode: [],
        plnaStdType: commonCodesGetCommonCode("PLAN_STD_TYPE",false,false,),
        bsnCode: commonCodesGetCommonCode("BSN_CODE",false,false,),
        fields: { text: "comName", value: "comCode" },
        classCodefields: { text: "className", value: "classCode" },
      },

      //그리드 필드
      gridField: {
        title: "영업계획기준",
        count : 0,
        dataSource: [],
      },


    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return (
          this.isShowMutilSelectPopup
      );
    },
    initGrid() {
      // console.log("initGrid");
      const gridColumns= [
        { field: "planStdId",       textAlign: 'center', allowEditing: false, width: 70,  visible: false, type: "string",  headerText: "계획번호", },
        { field: "planYear",        textAlign: 'left',   allowEditing: false, width: 0,   visible: false, type: "string",  headerText: "기준년도", },

        { field: "planStdNm",       textAlign: 'center', allowEditing: this.isSave,  width: 80,  visible: true,  type: "string",  headerText: "기준 명칭"},
        { field: "sortSno",         textAlign: 'center', allowEditing: this.isSave,  width: 40,  visible: true,  type: "number",  headerText: "순서",isNumericType: true, },
        { field: "planStdType",     textAlign: 'center', allowEditing: this.isSave,  width: 70,  visible: true,  type: "string",  headerText: "타입",
          editType: "dropdownedit", isCommonCodeField: true, dataSource: this.commonCodeOptions.plnaStdType },
        { field: "classCode",       textAlign: 'left',   allowEditing: false, width: 150, visible: false, type: "string",  headerText: "상품분류", },
        { field: "className",       textAlign: 'left',   allowEditing: false, width: 150, visible: true,  type: "string",  headerText: "상품분류", },
        { field: "useFlag",         textAlign: 'center', allowEditing: this.isSave,  width: 60,  visible: true,  type: "boolean", headerText: "사용여부", displayAsCheckBox: true, editType: "booleanedit", },
      ];
      const rule = {
        sortSno:      {required: true,   duplicateCheck: true,},
        planStdNm:    {required: true,},
        planStdType:  {required: true,},
      };
      const pushColumn = {
        headerText: '영업구분별 비율',
            field: 'salesPlanRate',
            visible: false,
            columns:[],
      };

      this.commonCodeOptions.bsnCode.forEach(bsnItem=>{
        pushColumn.columns.push( { field: bsnItem.comCode,   textAlign: 'right',  allowEditing: true, width: 50,   visible: false,  type: "string",  headerText: bsnItem.comName,
                                   isNumericType: true, inputNumberProperty: {allowDot:true ,maxUnderDotLength: 1 },});
        rule[bsnItem.comCode] = {
            required: true, min:0 , max:9.9
        };
      });

      gridColumns.push(pushColumn);

      return {
        columns: gridColumns,
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", morde: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
        validationModification: true,
        validationRules: rule
      };
    },


  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // console.log("created");
     this.initData();
  },
  mounted() {
    // console.log("mounted");
    this.onClickSearch();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
//region ******************************* api 호출 *******************************
    async findList() {
      this.gridField.dataSource = await SalesPlan.getStandard(this.searchFilter);
      this.isSave = await SalesPlan.validationSaveStd(this.searchFilter);
    },
    async save(args) {
      this.gridField.dataSource = await SalesPlan.saveStandard(args);
    },
    async copyPreYear(args) {
      this.gridField.dataSource = await SalesPlan.copyPreYear(args);
    },
//endregion
    //region ******************************* 버튼 이벤트 *******************************
    //초기화
    async initData() {
      this.searchFilter.planYear = moment().format(DATE_FORMAT_YYYY);  //마감년 기본 값
      this.commonCodeOptions.classCode = await SalesPlan.getProductClass({depth:2});
      this.commonCodeOptions.classCode.forEach((data, idx) => {
        if(data.parentClassName) {
          data.className = data.parentClassName + '-' + data.className;
        }
      });

    },
    //조회
    async onClickSearch() {
      await this.findList();
    },
    //마감년 변경시
    onYearChange() {
       this.onClickSearch();
    },
    //저장
    async onClickSave() {
      //1. 그리드 필수 입력체크
      if(!this.$refs.grid.validate()){
        return;
      }

      if(this.$refs.grid.getBatchCurrentViewRecords().length>0 && this.$refs.grid.getBatchCurrentViewRecords().filter((item)=> item.planStdType=='PERWEEKDAY').length!==1){
        this.errorToast("타입-주중인원은 하나가 필수 입니다.");
        return;
      }
      if(this.$refs.grid.getBatchCurrentViewRecords().length>0 && this.$refs.grid.getBatchCurrentViewRecords().filter((item)=> item.planStdType=='PERWEEKEND').length!==1){
        this.errorToast("타입-주말인원은 하나가 필수 입니다.");
        return;
      }
      if(this.$refs.grid.getBatchCurrentViewRecords().length>0 && this.$refs.grid.getBatchCurrentViewRecords().filter((item)=> item.planStdType=='TEAM').length!==1){
        this.errorToast("타입-팀수는 하나가 필수 입니다.");
        return;
      }
      if( this.$refs.grid.getBatchCurrentViewRecords().find((item)=> item.planStdType=='SALES' &&
            (item.classCode === null || item.classCode === undefined || item.classCode === "")))
      {
        this.errorToast("타입-매출의 상품분류는 필수 입니다.");
        return;
      }
      await this.save(this.$refs.grid.getBatchChanges());
      this.infoToast(this.$t('main.popupMessage.saved'));
      await this.onClickSearch();
    },
    //row추가
    onClickAddRow(){
      this.$refs.grid.addRecord({
        planYear: this.searchFilter.planYear,
        planStdNm:"",
        sortSno: 0,
        plnaStdType: "",
        classCode:"",
        className:"",
        useFlag: true,
      });
    },
    //row삭제
    onClickDelRow(){
       this.$refs.grid.deleteRecord();
    },
    //이전년도 복사
    async onClickCopyPreYear() {
      await this.copyPreYear({planYear: this.searchFilter.planYear});
      await this.onClickSearch();
    },
    //엑셀 출력
    onClickExcel() {
       this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //리포트 출력
    onClickPrint() {
      //
    },
    //endregion
    //region ******************************* 팝업 이벤트 *******************************
    onClickMutilSelectPopupShow(args) {
      const multiSelect = {
        result:args,
        dataSource: this.commonCodeOptions.classCode,
        fields: { text: "className", value: "classCode" },
      };
       this.isShowMutilSelectPopup = true;
      this.$nextTick(() => {
        this.$refs.multiSelectPopup.initShow("상품분류",multiSelect);
      });
     
    },
    onMutilSelectPopupConfirm(multiSelect) {
      const selectedRowIndex= this.$refs.grid.getSelectedRows()[0].rowIndex;
      this.$refs.grid.updateCell(selectedRowIndex,"classCode",multiSelect.result.join(","));
      const className = multiSelect.result.map((item) => {
            return  this.commonCodeOptions.classCode.filter((code)=> code.classCode === item )[0].className;
       }).join(',');
      this.$refs.grid.updateCell(selectedRowIndex,"className",className);
      this.isShowMutilSelectPopup = false;
    },
    onMutilSelectPopupClose() {
      this.isShowMutilSelectPopup = false;

    },
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (['className','classCode'].includes(field)) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (['className','classCode'].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 클릭
    onGridClick(args){
      const {column:{field},rowIndex} = args;
      if (['className','classCode'].includes(field)) {
        console.log(rowIndex);
        const rowData = this.$refs.grid.getBatchCurrentViewRecords()[rowIndex];
        const classCode = rowData.classCode || "";
        const planStdType = rowData.planStdType;
        if(planStdType==="SALES") {
          this.onClickMutilSelectPopupShow(classCode.split(","));
        }
      }
    },
    onCellSaved(args){
      const { rowData, columnName } = args;
      const index = this.$refs.grid.getRowIndexByPrimaryKey(rowData._rid);
      if (columnName === "planStdType") {
        const planStdType = this.$refs.grid.getBatchCurrentViewRecords()[index].planStdType;
        rowData.planStdType = planStdType;

        if (planStdType === "SALES") {
          const matchedItem = this.gridField.dataSource.find(item => item.planStdId === rowData.planStdId) || {};
          rowData.classCode = matchedItem.classCode || "";
          rowData.className = matchedItem.className || "";
        } else {
          rowData.classCode = "";
          rowData.className = "";
        }
     
        this.$refs.grid.updateRow(index,rowData);
      }

    },
    //endregion
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>

</style>
